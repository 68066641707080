import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import Link from '@nubank/nuds-web/components/Link/Link';

export const companyFooterTypes = {
  FULL: 'FULL',
  ONLY_CREDITS: 'CREDITS',
  ONLY_SAVINGS: 'SAVINGS',
  ONLY_SAVINGS_WAITLIST: 'SAVINGS_WAITLIST',
  FULL_WAITLIST: 'FULL_WAITLIST',
};

const companyFooterLayouts = {
  FULL: {
    showSavingsCompany: true,
    showCreditsCompany: true,
  },
  CREDITS: {
    showSavingsCompany: false,
    showCreditsCompany: true,
  },
  SAVINGS: {
    showSavingsCompany: true,
    showCreditsCompany: false,
  },
  SAVINGS_WAITLIST: {
    showSavingsCompany: true,
    showCreditsCompany: false,
  },
  FULL_WAITLIST: {
    showSavingsCompany: true,
    showCreditsCompany: true,
  },
};

const FooterCompany = ({
  savings,
  credits,
  companyFooterType,
  footerType,
}) => {
  const {
    showSavingsCompany,
    showCreditsCompany,
  } = {
    ...companyFooterLayouts.FULL,
    ...companyFooterLayouts[companyFooterType],
  };

  const border = footerType === 'ONLY_COMPANY' ? '' : '2px solid black';

  return (
    <Box
      data-testid="footer-company"
      display="grid"
      borderTop={border}
      color="white.black"
      gridTemplateColumns={{ lg: '50% 50%', xl: '50% 50%' }}
      gridTemplateAreas={{
        xs: '"savings" "credit"',
        md: '"savings" "credit"',
        lg: '"savings credit"',
      }}
    >
      {showSavingsCompany && (
        <Box
          data-testid="footer-savings"
          gridArea="savings"
          marginTop="3rem"
          marginBottom="2rem"
          borderRight={{
            lg: showCreditsCompany && '2px solid rgba(17,17,17,0.1)',
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns={{ md: '60% 40%', lg: '60% 40%', xl: '60% 40%' }}
            padding="1rem 0rem 1rem"
            alignItems="center"
            marginRight={100}
          >
            <Link
              href="https://www.superfinanciera.gov.co/jsp/60886"
            >
              <Image
                alt={savings.regulatorSecond.alt}
                webp
                srcSet={savings.regulatorSecond.images}
              />
            </Link>
            <Link href="https://www.fogafin.gov.co/">
              <Image
                alt={savings.regulatorFirst.alt}
                srcSet={savings.regulatorFirst.images}
                webp
              />
            </Link>
          </Box>
          <Box
            marginRight={{
              md: 0,
              lg: 100,
            }}
          >
            <Typography
              color="black"
              css={css`strong { font-weight: bold;}`}
              variant="paragraph1"
              intlKey={savings.text}
              intlValues={{
                strong: msg => (
                  <strong>{ msg }</strong>
                ),
              }}
            />
          </Box>
        </Box>
      )}
      {showCreditsCompany && (
        <Box
          data-testid="footer-credit"
          gridArea="credit"
          marginTop="3rem"
          marginBottom="2rem"
          borderTop={{
            md: '2px solid rgba(17,17,17,0.1)',
            lg: '0px',
          }}
        >
          <Box
            position="relative"
            right="0"
            display="grid"
            gridTemplateColumns={{ md: '40% 60%', lg: '65% 35%', xl: '70% 30%' }}
            gridTemplateAreas={{
              xs: '"logo1 logo2"',
              md: '"logo1 logo2"',
              lg: '"logo2 logo1"',
            }}
            backgroundColor="white.black"
            padding={{
              md: '3rem 0rem 1.5rem',
              lg: '1rem 0rem 1.5rem',
            }}
          >
            <Box gridArea="logo2" />
            <Box gridArea="logo1">
              <Link href="https://www.sic.gov.co/">
                <Image
                  alt="todo"
                  width="218px"
                  height="47px"
                  srcSet={credits.regulatorFirst.images}
                />
              </Link>
            </Box>
          </Box>
          <Box
            marginLeft={{
              md: 0,
              lg: 115,
            }}
            css={css`bottom: 0;`}
          >
            <Typography
              textAlign={{
                xs: 'left',
                lg: 'right',
              }}
              css={css`strong { font-weight: bold;}`}
              variant="paragraph1"
              intlKey={credits.text}
              intlValues={{
                strong: msg => (
                  <strong>{ msg }</strong>
                ),
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

FooterCompany.propTypes = {
  companyFooterType: PropTypes.string,
  credits: PropTypes.shape({
    regulatorFirst: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      images: PropTypes.shape({
        lg: PropTypes.string.isRequired,
        md: PropTypes.string.isRequired,
        xs: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  footerType: PropTypes.string,
  savings: PropTypes.shape({
    regulatorFirst: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      images: PropTypes.shape({
        lg: PropTypes.string.isRequired,
        md: PropTypes.string.isRequired,
        xs: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    regulatorSecond: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      images: PropTypes.shape({
        lg: PropTypes.string.isRequired,
        md: PropTypes.string.isRequired,
        xs: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

FooterCompany.defaultProps = {
  companyFooterType: companyFooterTypes.FULL,
  footerType: '',
};

export default FooterCompany;
