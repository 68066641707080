import React from 'react';
import PropTypes from 'prop-types';
import Link from '@nubank/nuds-web/components/Link/Link';
import Box from '@nubank/nuds-web/components/Box/Box';
import { useIntl } from 'react-intl';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

import MenuLinksProps from './MultiProductHeaderProps';

const { navigation } = MenuLinksProps;

const NavigationListLink = styled(Link)`
  position: relative;
  display: flex;
  width: auto;
  align-items: center;

   &.active { ::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 10px;
      background-color: ${props => nuDSColor(props.color)};
    }
  }
`;

const HeaderLinks = ({
  nuLogoColor, href, showHeaderTopMenu, queryParams,
}) => {
  const { formatMessage } = useIntl();
  const { route } = useRouter();
  const links = navigation;

  return (
    <>
      <Link
        href={href + queryParams}
        aria-label={formatMessage({ id: 'HEADER.LOGO.ARIA_LABEL' })}
        marginRight="8x"
      >
        <NuLogo variant={nuLogoColor} />
      </Link>

      {showHeaderTopMenu && (
      <Box
        tag="ul"
        display={{ xs: 'none', md: 'none', lg: 'flex' }}
        alignItems="center"
      >

        {links.map(({
          label, path, variant, keepQueryParams,
        }) => (
          <Box tag="li" key={label}>
            <NavigationListLink
              color="black"
              marginRight="6x"
              intlKey={label}
              href={formatMessage({ id: path }) + (keepQueryParams ? queryParams : '')}
              variant={variant}
              className={formatMessage({ id: path }) === route ? 'active' : ''}
            />
          </Box>
        ))}
      </Box>
      )}
    </>
  );
};

HeaderLinks.defaultProps = {
  nuLogoColor: 'black',
  href: '/',
  showHeaderTopMenu: true,
  queryParams: '',
};

HeaderLinks.propTypes = {
  href: PropTypes.string,
  nuLogoColor: PropTypes.string,
  queryParams: PropTypes.string,
  showHeaderTopMenu: PropTypes.bool,
};

export default HeaderLinks;
