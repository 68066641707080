import { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const RadioButtonOption = ({ intKey }) => (
  <Typography variant="paragraph2" tag="label" color="black.default" css={css`font-weight: 600;`}>
    <FormattedMessage id={intKey} />
  </Typography>
);

RadioButtonOption.propTypes = {
  intKey: PropTypes.string.isRequired,
};

export const documentTypes = [{
  value: 'CC',
  label: <RadioButtonOption intKey="PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.CITIZENSHIP_CARD" />,
}, {
  value: 'CE',
  label: <RadioButtonOption intKey="PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.FOREIGNER_ID" />,
}];
