import PropTypes from 'prop-types';
import React from 'react';

import FeedbackEmail from './components/FeedbackEmail/FeedbackEmail';
import FeedbackProductApproved from './components/FeedbackProductApproved/FeedbackProductApproved';
import FeedbackProductDenied from './components/FeedbackProductDenied/FeedbackProductDenied';
import DecisionStepProps from './DecisionStepProps';

const DecisionStep = ({
  prospectEmail,
  prospectNames,
  realtimeResult,
}) => {
  const { analysisResult } = realtimeResult;
  const defaultApprovedProps = DecisionStepProps.approved.multiProduct;
  const productsApproved = analysisResult?.template?.id;
  switch (productsApproved) {
    case 'credit_card':
      return (
        <FeedbackProductApproved
          prospectEmail={prospectEmail}
          prospectNames={prospectNames}
          defaultProps={defaultApprovedProps}
        />
      );
    case 'nuconta':
    case 'credit_card_and_nuconta':
      return (
        <FeedbackProductApproved
          prospectEmail={prospectEmail}
          prospectNames={prospectNames}
          multiProduct
          defaultProps={DecisionStepProps.approved.multiProduct}
        />
      );
    case 'no_products':
      return (
        <FeedbackProductDenied
          prospectEmail={prospectEmail}
        />
      );
    default:
      return (
        <FeedbackEmail
          prospectEmail={prospectEmail}
          prospectNames={prospectNames}
        />
      );
  }
};

DecisionStep.propTypes = {
  prospectEmail: PropTypes.string.isRequired,
  prospectNames: PropTypes.string.isRequired,
  realtimeResult: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      analysisResult: PropTypes.shape({
        template: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
      prospect: PropTypes.shape({
        marketingId: PropTypes.string.isRequired,
        prospectType: PropTypes.string.isRequired,
      }),
    }),
  ]).isRequired,
};

export default DecisionStep;
