import React, { useState, useContext } from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';
import Box from '@nubank/nuds-web/components/Box/Box';

import {
  hasTwoOrMoreCharsOnFirstWord,
  optionalHasTwoOrMoreCharsOnFirstWord,
} from '@nubank/www-latam-commons/utils/form/validationUtils';
import { documentTypes } from 'utils/documentTypes';
import { SiteContext } from 'components/SiteContext/SiteContext';

import { validateDocumentID, validatePhone, hasLessThanTwentyChars } from '../../../utils/form/validationUtils';

import RegistrationRealtimeLoader from './patterns/RegistrationRealtimeLoader/RegistrationRealtimeLoader';
import FormHeader from './patterns/FormHeader/FormHeader';
import PersonalInfoStep from './patterns/PersonalInfoStep/PersonalInfoStep';
import DecisionStep from './patterns/DecisionStep/DecisionStep';
import { StepWrapper, LoadingStepWrapper, ResultStepWrapper } from './styles/StepWrapper';
import { StyledForm } from './styles/StyledForm';
import personalInfoStepProps from './patterns/PersonalInfoStep/PersonalInfoStepProps';

export const extraValidations = {
  firstSurname: value => hasTwoOrMoreCharsOnFirstWord(value),
  nineteenMaxLength: hasLessThanTwentyChars,
  names: value => hasTwoOrMoreCharsOnFirstWord(value),
  secondSurname: optionalHasTwoOrMoreCharsOnFirstWord,
  phoneNumber: validatePhone,
  idNumber: validateDocumentID,
};

const ccDocumentType = documentTypes.find(({ value }) => value === 'CC').value;

const formInitialValues = {
  confirmEmail: '',
  names: '',
  firstSurname: '',
  secondSurname: '',
  idNumber: '',
  phoneNumber: '',
  policyAccepted: false,
  whatsappAccepted: false,
  documentType: ccDocumentType,
};

const ProspectRegistrationForm = () => {
  const [realtimeResult, setRealtimeResult] = useState(false);
  const { usePurpleAsBackgroundInRegistrationForm } = useContext(SiteContext);
  const defaultInfoStepProps = personalInfoStepProps.multiProduct;
  const formExperiments = {};

  return (
    <StyledForm
      renderHeader={() => <FormHeader />}
      extraValidations={extraValidations}
    >
      <StepWrapper>
        <PersonalInfoStep
          setRealtimeResult={setRealtimeResult}
          finalInitialValues={{ ...formInitialValues }}
          {...formExperiments}
          defaultProps={defaultInfoStepProps}
        />
      </StepWrapper>
      <LoadingStepWrapper>
        <Form.Step isForm={false}>
          <Box>
            <RegistrationRealtimeLoader />
          </Box>
        </Form.Step>
      </LoadingStepWrapper>
      <ResultStepWrapper approved={usePurpleAsBackgroundInRegistrationForm}>
        <Form.Step isForm={false}>
          {({ formsValues }) => (
            <DecisionStep
              prospectEmail={formsValues.email}
              prospectNames={formsValues.names || ''}
              realtimeResult={realtimeResult}
              {...formExperiments}
            />
          )}
        </Form.Step>
      </ResultStepWrapper>
    </StyledForm>
  );
};

export default ProspectRegistrationForm;
