import React from 'react';
import PropTypes from 'prop-types';

import { MainContentContainer } from './styles/MainContentContainer';

const MainContent = ({ children, styles }) => (
  <MainContentContainer id="main-content" {...styles}>
    { children }
  </MainContentContainer>
);

MainContent.defaultProps = {
  styles: {
    paddingTop: '4rem',
  },
};

MainContent.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.shape({
    paddingTop: PropTypes.string,
  }),
};

export default MainContent;
