import { sanitizeAndValidateRegex } from '@nubank/www-latam-commons/utils/form/validationUtils';

const FOUR_MIN_AND_TWELVE_MAX_DIGITS_REGEX = /^((?!(0))\d{4,12})$/;
const FOUR_MIN_AND_EIGHT_MAX_DIGITS_REGEX = /^\d{4,8}$/;
const STARTS_WITH_THREE_AND_HAS_10_DIGITS = /^3\d{9}$/;
const NOT_A_DIGIT = /\D/g;
const NINETEEN_MAX_CHARS = /^.{0,19}$/;

function validateForeignerId(str) {
  return (
    Boolean(str)
    && Number(str) > 1000
    && sanitizeAndValidateRegex(str, FOUR_MIN_AND_EIGHT_MAX_DIGITS_REGEX));
}

function validateCitizenshipCard(str) {
  return Boolean(str) && sanitizeAndValidateRegex(str, FOUR_MIN_AND_TWELVE_MAX_DIGITS_REGEX);
}

export function validateDocumentID(str, _, { documentType }) {
  return documentType === 'CC'
    ? validateCitizenshipCard(str)
    : validateForeignerId(str);
}

export function validatePhone(str) {
  const numberWithoutSpace = str.replace(NOT_A_DIGIT, '');
  return sanitizeAndValidateRegex(numberWithoutSpace, STARTS_WITH_THREE_AND_HAS_10_DIGITS);
}

export function hasLessThanTwentyChars(str) {
  return Boolean(str) && sanitizeAndValidateRegex(str, NINETEEN_MAX_CHARS);
}
