const ComponentDefaultProps = {
  navigation: [{
    label: 'MULTI_PRODUCT.MENU.HOME.LABEL',
    path: 'MULTI_PRODUCT.MENU.HOME.PATH',
    keepQueryParams: true,
  }, {
    label: 'MULTI_PRODUCT.MENU.CREDIT_CARD.LABEL',
    path: 'MULTI_PRODUCT.MENU.CREDIT_CARD.PATH',
    keepQueryParams: true,
  }, {
    label: 'MULTI_PRODUCT.MENU.SAVINGS.LABEL',
    path: 'MULTI_PRODUCT.MENU.SAVINGS.PATH',
    keepQueryParams: true,
  }, {
    label: 'MULTI_PRODUCT.MENU.ABOUT_US.LABEL',
    path: 'MULTI_PRODUCT.MENU.ABOUT_US.PATH',
    variant: 'action',
    keepQueryParams: false,
  }, {
    label: 'MULTI_PRODUCT.MENU.BLOG.LABEL',
    path: 'MULTI_PRODUCT.MENU.BLOG.PATH',
    variant: 'action',
    keepQueryParams: false,
  },
  ],
  navigationPreMultiproduct: [{
    label: 'MULTI_PRODUCT.MENU.CREDIT_CARD.LABEL',
    path: 'MENU.HOME.PATH',
    keepQueryParams: true,
  }, {
    label: 'MULTI_PRODUCT.MENU.SAVINGS.LABEL',
    path: 'MULTI_PRODUCT.MENU.SAVINGS_WAITLIST.PATH',
    keepQueryParams: true,
  }, {
    label: 'MULTI_PRODUCT.MENU.ABOUT_US.LABEL',
    path: 'MULTI_PRODUCT.MENU.ABOUT_US.PATH',
    variant: 'action',
    keepQueryParams: false,
  }, {
    label: 'MULTI_PRODUCT.MENU.BLOG.LABEL',
    path: 'MULTI_PRODUCT.MENU.BLOG.PATH',
    variant: 'action',
    keepQueryParams: false,
  },
  ],
};

export default ComponentDefaultProps;
