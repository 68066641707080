import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { useIntl } from 'react-intl';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import StyledCollapse from '../../styles/StyledCollapse';
import { LegalDocumentLink } from '../../../../components/GetLegalDocuments/GetLegalDocuments';
import { useGetDocumentsByRoute } from '../../../../hooks/useGetDocuments';

const borderBox = '2px solid rgba(17,17,17,0.2)';

const gridTemplateAreas = {
  sm: '"contact" "transparency" "navigate" "products"',
  md: '"contact transparency" "navigate products"',
  lg: '"contact transparency navigate products"',
};

const gridTemplateAreasWorld = {
  sm: '"contact" "transparency" "navigate" "products" "world"',
  md: '"contact transparency" "navigate products" "world"',
  lg: '"contact transparency navigate products world"',
};

const FooterNavigation = ({
  contact,
  transparency,
  navigate,
  world,
  products,
}) => {
  const { formatMessage } = useIntl();
  const legalDocuments = useGetDocumentsByRoute();
  const gridTemplateColumnsLg = world ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr';

  return (
    <Box
      display="grid"
      marginBottom={{ xs: '6x', lg: '6x' }}
      padding="1rem 0.5rem"
      borderTop={{ md: borderBox, lg: borderBox }}
      gridGap={{
        md: '50px',
      }}
      gridTemplateColumns={{
        md: '1fr 1fr 1fr',
        lg: gridTemplateColumnsLg,
      }}
      gridTemplateAreas={world ? gridTemplateAreasWorld : gridTemplateAreas}
    >
      <Box gridArea="contact" marginBottom="1rem">
        <StyledCollapse id="contact" initiallyOpen>
          <Collapse.Header>
            <Typography
              intlKey={contact.title}
              color="black"
              variant="heading3"
              strong
            />
          </Collapse.Header>
          <Collapse.Body>
            <Box
              display="flex"
              flexDirection="column"
              marginTop="4x"
            >
              {contact.generals.map(({
                text, mailRef, mailLabel, border,
              }) => (
                <Box key={`contact-${text}`}>
                  {border
                    && (
                    <Box
                      padding="1rem"
                      borderTop="1px solid #EFEFEF"
                      key={text}
                    />
                    )}
                  <Typography
                    color="black"
                    variant="paragraph1"
                    marginBottom={16}
                    intlKey={text}
                    intlValues={{
                      link: (
                        <Link
                          typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                          href={formatMessage({ id: mailRef })}
                          intlKey={mailLabel}
                          gutterBottom={0.5}
                          variant="action"
                          type="email"
                        />
                      ),
                    }}
                  />
                </Box>
              ))}

              {contact.phones
            && (
            <Typography
              color="black"
              variant="paragraph1"
              css={css`strong { font-weight: bold;}`}
              marginBottom={16}
              intlKey={contact.phones}
              intlValues={{
                br: <br />,
                strong: msg => (
                  <strong>{ msg }</strong>
                ),
              }}
            />
            )}
              {contact.consumerAdvocate && (
              <Typography
                color="black"
                variant="paragraph1"
                intlKey={contact.consumerAdvocate}
                intlValues={{
                  link: (
                    <Link
                      typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                      href={formatMessage({ id: contact.consumerAdvocateBlogLink })}
                      intlKey={contact.consumerAdvocateLink}
                      variant="action"
                    />
                  ),
                }}
              />
              )}

              {contact.consumerAdvocate && (
              <Typography
                color="black"
                variant="paragraph1"
                marginBottom={16}
                intlKey={contact.consumerAdvocateOther}
                intlValues={{
                  br: <br />,
                  link: (
                    <Link
                      typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                      href={formatMessage({ id: contact.consumerAdvocateWebLink })}
                      intlKey={contact.consumerAdvocateWeb}
                      gutterBottom={0.5}
                      variant="action"
                      type="email"
                    />
                  ),
                }}
              />
              )}
            </Box>
          </Collapse.Body>
        </StyledCollapse>
      </Box>
      <Box gridArea="transparency" marginBottom="1rem">
        <StyledCollapse id="transparency" initiallyOpen>
          <Collapse.Header>
            <Typography
              intlKey={transparency.title}
              color="black"
              variant="heading3"
              strong
            />
          </Collapse.Header>

          <Collapse.Body>
            <Box
              display="flex"
              flexDirection="column"
              marginTop="4x"
            >

              {transparency.policyPrivacy.path && (
                <LegalDocumentLink
                  documentName={transparency.policyPrivacy.path.includes('NUCF') ? 'nu_CF_data_policy' : 'nu_SA_data_policy'}
                  oldPath={formatMessage({ id: transparency.policyPrivacy.path })}
                  documentsResponse={legalDocuments}
                  typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                  intlKey={transparency.policyPrivacy.label}
                  gutterBottom={1}
                />
              )}

              {!transparency.policyPrivacy.path && (
              <Typography
                intlKey={transparency.policyPrivacy.label}
                color="black"
                variant="paragraph1"
                gutterBottom={1}
                intlValues={{
                  nucf: (
                    <LegalDocumentLink
                      documentName="nu_CF_data_policy"
                      oldPath={formatMessage({ id: transparency.policyPrivacy.nucfPath })}
                      documentsResponse={legalDocuments}
                      typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                      rel="noopener"
                      target="_blank"
                      intlKey={transparency.policyPrivacy.nucfLabel}
                    />
                  ),
                  nusa: (
                    <LegalDocumentLink
                      documentName="nu_SA_data_policy"
                      oldPath={formatMessage({ id: transparency.policyPrivacy.nusaPath })}
                      documentsResponse={legalDocuments}
                      typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                      rel="noopener"
                      target="_blank"
                      intlKey={transparency.policyPrivacy.nusaLabel}
                    />
                  ),
                }}
              />
              )}
              {transparency.conditions && transparency.conditions.map(condition => (
                <Link
                  key={condition.label}
                  typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                  href={formatMessage({ id: condition.path })}
                  intlKey={condition.label}
                  gutterBottom={1}
                />
              ))}
              <Typography
                intlKey={transparency.conductCode.label}
                color="black"
                variant="paragraph1"
                gutterBottom={1}
                intlValues={{
                  spanish: (
                    <Link
                      typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                      rel="noopener"
                      target="_blank"
                      href={formatMessage({ id: transparency.conductCode.spanishPath })}
                      intlKey={transparency.conductCode.spanishLabel}
                    />
                  ),
                  english: (
                    <Link
                      typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                      rel="noopener"
                      target="_blank"
                      href={formatMessage({ id: transparency.conductCode.englishPath })}
                      intlKey={transparency.conductCode.englishLabel}
                    />
                  ),
                }}
              />
              <Typography
                color="black"
                variant="paragraph1"
                intlKey={transparency.ethics.label}
                gutterBottom={1}
                intlValues={{
                  link: (
                    <Link
                      typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                      rel="noopener"
                      target="_blank"
                      href={formatMessage({ id: transparency.ethics.path })}
                      intlKey={transparency.ethics.text}
                    />
                  ),
                }}
              />
              {transparency?.impact && (
              <Link
                typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                href={formatMessage({ id: transparency.impact.path })}
                intlKey={transparency.impact.label}
                variant="action"
                gutterBottom={1}
              />
              )}
              <Link
                typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                href={formatMessage({ id: transparency.investor.path })}
                intlKey={transparency.investor.label}
                variant="action"
                gutterBottom={1}
              />
              {transparency?.impact && (
              <Link
                typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                href={formatMessage({ id: transparency.advocate.path })}
                intlKey={transparency.advocate.label}
                variant="action"
                gutterBottom={1}
              />
              )}
            </Box>

          </Collapse.Body>
        </StyledCollapse>
      </Box>
      <Box gridArea="navigate" marginBottom="1rem">
        <StyledCollapse id="navigate" initiallyOpen>
          <Collapse.Header>
            <Typography
              intlKey={navigate.title}
              color="black"
              variant="heading3"
              strong
            />
          </Collapse.Header>

          <Collapse.Body>
            <Box
              display="flex"
              flexDirection="column"
              marginTop="4x"
            >
              {navigate.options.map(({
                label, path, variant = 'text',
              }) => (
                <Link
                  key={label}
                  typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                  href={formatMessage({ id: path })}
                  variant={variant}
                  intlKey={label}
                  gutterBottom={1}
                  width="fit-content"
                />
              ))}
            </Box>
          </Collapse.Body>
        </StyledCollapse>
      </Box>
      <Box gridArea="products" marginBottom="1rem">
        <StyledCollapse id="products" initiallyOpen>
          <Collapse.Header>
            <Typography
              intlKey={products.title}
              color="black"
              variant="heading3"
              strong
            />
          </Collapse.Header>

          <Collapse.Body>
            <Box
              display="flex"
              flexDirection="column"
              marginTop="4x"
            >
              {products.options.map(({
                label, path, variant = 'text',
              }) => (
                <Link
                  key={label}
                  typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                  href={formatMessage({ id: path })}
                  variant={variant}
                  intlKey={label}
                  gutterBottom={1}
                  width="fit-content"
                />
              ))}
            </Box>
          </Collapse.Body>
        </StyledCollapse>
      </Box>

      {world && (
      <Box gridArea="world" marginBottom="1rem">
        <StyledCollapse id="world" initiallyOpen>
          <Collapse.Header>
            <Typography
              intlKey={world.title}
              color="black"
              variant="heading4"
              strong
            />
          </Collapse.Header>

          <Collapse.Body>
            <Box
              display="flex"
              flexDirection="column"
              marginTop="4x"
            >
              {world.sites.map(({
                label, path, variant = 'text',
              }) => (
                <Link
                  key={label}
                  typographyProps={{ color: 'black', variant: 'paragraph1', strong: true }}
                  href={formatMessage({ id: path })}
                  variant={variant}
                  intlKey={label}
                  gutterBottom={1}
                  width="fit-content"
                />
              ))}
            </Box>
          </Collapse.Body>
        </StyledCollapse>
      </Box>
      )}
    </Box>
  );
};

FooterNavigation.defaultProps = {
  world: null,
  products: null,
};

FooterNavigation.propTypes = {
  contact: PropTypes.shape({
    consumerAdvocate: PropTypes.string,
    consumerAdvocateBlogLink: PropTypes.string,
    consumerAdvocateLink: PropTypes.string,
    consumerAdvocateOther: PropTypes.string,
    consumerAdvocateWeb: PropTypes.string,
    consumerAdvocateWebLink: PropTypes.string,
    generals: PropTypes.arrayOf(
      PropTypes.shape({
        border: PropTypes.bool.isRequired,
        mailLabel: PropTypes.string.isRequired,
        mailRef: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ).isRequired,
    phones: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  navigate: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  products: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ).isRequired,
    title: PropTypes.string.isRequired,
  }),
  transparency: PropTypes.shape({
    advocate: PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
    conditions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ).isRequired,
    conductCode: PropTypes.shape({
      englishLabel: PropTypes.string.isRequired,
      englishPath: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      spanishLabel: PropTypes.string.isRequired,
      spanishPath: PropTypes.string.isRequired,
    }).isRequired,
    ethics: PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    impact: PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
    investor: PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
    policyPrivacy: PropTypes.shape({
      label: PropTypes.string.isRequired,
      nucfLabel: PropTypes.string,
      nucfPath: PropTypes.string,
      nusaLabel: PropTypes.string,
      nusaPath: PropTypes.string,
      path: PropTypes.string,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  world: PropTypes.shape({
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ).isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default FooterNavigation;
