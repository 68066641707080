const defaultProps = {
  title: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.TITLE',
  },
  subtitle: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.MESSAGE',
    strong: true,
    variant: 'paragraph2',
  },
  email: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.VALIDATION_MESSAGE',
  },
  confirmEmail: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CONFIRM_EMAIL.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.VALIDATION_MESSAGE',
    differentValidationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.DIFFERENT_VALIDATION_MESSAGE',
  },
  names: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.NAMES.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.NAMES.VALIDATION_MESSAGE',
  },
  firstSurname: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.VALIDATION_MESSAGE',
    maxCharValidationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.MAX_CHARS.VALIDATION_MESSAGE',
  },
  secondSurname: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.SECOND_SURNAME.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.SECOND_SURNAME.VALIDATION_MESSAGE',
  },
  documentType: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.VALIDATION_MESSAGE',
  },
  idNumber: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.LABEL',
    citizenValidationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.VALIDATION_MESSAGE.CITIZENSHIP_CARD',
    foreignerValidationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.VALIDATION_MESSAGE.FOREIGNER_ID',
  },
  phoneNumber: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PHONE.LABEL',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PHONE.VALIDATION_MESSAGE',
  },
  privacyPolicy: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY',
    validationMessage: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.VALIDATION_MESSAGE',
    links: [{
      name: 'privacyLink',
      path: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.PATH',
      pathLabel: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK.LABEL',
      strong: true,
      color: 'primary',
      variant: 'paragraph2',
    }],
    variant: 'paragraph2',
    strong: true,
  },
  whatsappMessage: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE',
    company: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE.COMPANY',
    variant: 'paragraph2',
    strong: true,
  },
  submitButton: {
    label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.SUBMIT_BUTTON',
  },
  snackBar: {
    label: 'PROSPECT_REGISTRATION_FORM.AUTHORIZATION_STEP.SNACKBAR_CLOSE',
  },
  genericErrorMessage: {
    label: 'PROSPECT_REGISTRATION_FORM.AUTHORIZATION_STEP.GENERIC.ERROR_MESSAGE',
  },
};

const ComponentDefaultProps = {
  singleProduct: defaultProps,
  multiProduct: {
    ...defaultProps,
    title: {
      label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.TITLE',
    },
    subtitle: {
      label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.MESSAGE',
      strong: false,
      variant: 'paragraph1',
    },
    subtitle2: {
      label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.MESSAGE2',
      strong: true,
      variant: 'paragraph2',
    },
    documentType: {
      label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.LABEL',
      validationMessage: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.VALIDATION_MESSAGE',
    },
    idNumber: {
      label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.LABEL',
      citizenValidationMessage: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.VALIDATION_MESSAGE.CITIZENSHIP_CARD',
      foreignerValidationMessage: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.VALIDATION_MESSAGE.FOREIGNER_ID',
    },
    privacyPolicy: {
      label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY',
      validationMessage: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.VALIDATION_MESSAGE',
      links: [{
        name: 'privacyLink',
        path: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK.PATH',
        pathLabel: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK.LABEL',
        strong: true,
        color: 'primary',
        variant: 'paragraph1',
      }, {
        name: 'privacyLink2',
        path: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK2.PATH',
        pathLabel: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK2.LABEL',
        strong: true,
        color: 'primary',
        variant: 'paragraph1',
      }],
      variant: 'paragraph1',
      strong: false,
    },
    whatsappMessage: {
      label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE',
      companies: [
        {
          name: 'SA',
          label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.TERMS_CONDITIONS.LINK.LABEL',
          strong: true,
          variant: 'span',
        },
        {
          name: 'CF',
          label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.TERMS_CONDITIONS.LINK2.LABEL',
          strong: true,
          variant: 'span',
        },
        {
          name: 'WhatsApp',
          label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE.COMPANY',
          strong: false,
          variant: 'link',
          path: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE.COMPANY.URL',
          color: 'primary',
        },
      ],
      variant: 'paragraph1',
      strong: false,
    },
    submitButton: {
      label: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.SUBMIT_BUTTON',
      iconProps: { name: 'arrow-right' },
    },
  },
};

export default ComponentDefaultProps;
