import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import PropTypes from 'prop-types';

import { SiteContext } from '../SiteContext/SiteContext';

const MenuHeader = dynamic(() => import('./styles/MenuHeader'));
const MenuContent = dynamic(() => import('./MenuContent'));

const Menu = ({ multiProduct, queryParams }) => {
  const siteCtx = useContext(SiteContext);
  const { isMenuOpen, closeMenu } = siteCtx;

  return (
    <Drawer
      id="main-menu"
      open={isMenuOpen}
      onClose={closeMenu}
      width="44.3125rem"
    >
      {({
        DrawerHeader,
        DrawerCloseButton,
        DrawerContent,

      }) => (
        <DrawerContent>
          <DrawerHeader>
            <DrawerCloseButton onClick={closeMenu} />
          </DrawerHeader>
          <MenuHeader>
            <NuLogo variant={multiProduct ? 'black' : 'primary'} width="44" height="32" />
          </MenuHeader>
          <MenuContent
            closeMenu={closeMenu}
            queryParams={queryParams}
          />
        </DrawerContent>
      )}
    </Drawer>
  );
};

Menu.propTypes = {
  multiProduct: PropTypes.bool,
  queryParams: PropTypes.string,
};

Menu.defaultProps = {
  multiProduct: true,
  queryParams: '',
};

export default Menu;
