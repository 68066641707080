import React, { useContext } from 'react';
import Button from '@nubank/nuds-web/components/Button/Button';
import { useIntl } from 'react-intl';

import { SiteContext } from '../../../../../components/SiteContext/SiteContext';

import { HeaderContainer } from './styles/HeaderContainer';

const FormHeader = () => {
  const {
    closeRegistrationForm,
    usePurpleAsBackgroundInRegistrationForm,
  } = useContext(SiteContext);
  const { formatMessage } = useIntl();

  return (
    <HeaderContainer>
      <Button
        data-testid="close-btn"
        variant="basic"
        styleVariant={usePurpleAsBackgroundInRegistrationForm ? 'white' : 'primary'}
        onClick={closeRegistrationForm}
        iconProps={{
          name: 'x', title: formatMessage({ id: 'FORM.HEADER.CLOSE_BUTTON.TITLE' }),
        }}
      />
    </HeaderContainer>
  );
};

export default FormHeader;
