import React from 'react';
import dynamic from 'next/dynamic';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import styled from 'styled-components';

import { Container } from './styles/Container';
import { Head, HeadImage } from './styles/Head';

const LoaderIcon = dynamic(() => import('./components/LoaderIcon/LoaderIcon'), { ssr: false });

const StyledHeadImage = styled(HeadImage)`
    max-width: 500px;
`;

function RegistrationRealtimeLoader() {
  return (
    <Container>
      <Head>
        <StyledHeadImage>
          <LoaderIcon />
        </StyledHeadImage>
        <Typography
          intlKey="COMMON.WIDGET.REALTIME_LOADER"
          color="primary"
          colorVariant="default"
          intlValues={{
            br: <br />,
          }}
        />
      </Head>
    </Container>
  );
}

export default RegistrationRealtimeLoader;
