import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

import HeaderLinks from './HeaderLinks';
import HeaderMenuButtons from './HeaderMenuButtons';

const Header = ({
  showCTA,
  CTA,
  showMenuBtn,
  nuLogoColor,
  showHeaderTopMenu,
  nuLogoHref,
  queryParams,
}) => (
  <Box
    position="fixed"
    top="0"
    zIndex="300"
    width="100%"
  >
    <Box
      tag="header"
      paddingVertical="4x"
      paddingHorizontal={{ xs: '8x', lg: 56 }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      backgroundColor="white.default"
      transition="background-color 300ms linear"
      maxHeight={64}
    >
      <Box
        tag="nav"
        display="flex"
        alignItems="center"
      >
        <HeaderLinks
          href={nuLogoHref}
          nuLogoColor={nuLogoColor}
          showHeaderTopMenu={showHeaderTopMenu}
          queryParams={queryParams}
        />
      </Box>
      {showCTA && CTA}
      {showMenuBtn && <HeaderMenuButtons />}

    </Box>
  </Box>
);

Header.defaultProps = {
  CTA: false,
  showCTA: false,
  showMenuBtn: false,
  nuLogoColor: 'black',
  nuLogoHref: '/',
  showHeaderTopMenu: true,
  queryParams: '',
};

Header.propTypes = {
  CTA: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.node]),
  nuLogoColor: PropTypes.string,
  nuLogoHref: PropTypes.string,
  queryParams: PropTypes.string,
  showCTA: PropTypes.bool,
  showHeaderTopMenu: PropTypes.bool,
  showMenuBtn: PropTypes.bool,
};

export default Header;
